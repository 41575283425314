import React from "react"
import { useEffect } from 'react';
// import { getCookieConsentValue } from "react-cookie-consent";

const Search = ({ currentPath }) => {
  // const { currentPath } = props

  // const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled')

  useEffect(() => {

    const gcse = document.createElement('script')
    gcse.src = 'https://cse.google.com/cse.js?cx=009597372997567409640:dq1l164n8sk'
    gcse.async = true

    document.head.appendChild(gcse)

    return () => {
        document.head.removeChild(gcse)   
      } 

  }, [currentPath])
  
  return (
    <div key={currentPath} className="gcse-searchbox-only" data-personalizedAds="false" data-resultsUrl="https://kotlet.tv/szukaj?s=gcse"></div>
  )
}

export default Search