import React from "react"
import Image from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"

const HomeCategories = () => {

  const data = useStaticQuery(graphql`
    query CategoriesQuery {
      deseryCiastaTERM: wpCategory(databaseId: {eq: 4}) {
        name
        uri
      }
      deseryCiastaIMG: wpPost(databaseId: {eq: 61575}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      obiadTERM: wpCategory(databaseId: {eq: 1}) {
        name
        uri
      }
      obiadIMG: wpPost(databaseId: {eq: 61802}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      maleDaniaTERM: wpCategory(databaseId: {eq: 5}) {
        name
        uri
      }
      maleDaniaIMG: wpPost(databaseId: {eq: 61745}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      chlebyTERM: wpCategory(databaseId: {eq: 171}) {
        name
        uri
      }
      chlebyIMG: wpPost(databaseId: {eq: 61303}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      zupyTERM: wpCategory(databaseId: {eq: 3}) {
        name
        uri
      }
      zupyIMG: wpPost(databaseId: {eq: 59432}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      salatkiTERM: wpCategory(databaseId: {eq: 575}) {
        name
        uri
      }
      salatkiIMG: wpPost(databaseId: {eq: 61824}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
      napojeTERM: wpCategory(databaseId: {eq: 6}) {
        name
        uri
      }
      napojeIMG: wpPost(databaseId: {eq: 60254}) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `)

  // const categories = data.allWpCategory.nodes

  return (
    <section className="home-categories">
      <h1>Znajdź przepisy na:</h1>
      <ul>
        <li>
          <Link to={data.deseryCiastaTERM.uri}>
            {data?.deseryCiastaIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.deseryCiastaIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.deseryCiastaTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.deseryCiastaTERM.name}</span>
          </Link>
        </li>
        <li>
          <Link to={data.obiadTERM.uri}>
            {data?.obiadIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.obiadIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.obiadTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.obiadTERM.name}</span>
          </Link>
        </li>
        <li>
          <Link to={data.maleDaniaTERM.uri}>
            {data?.maleDaniaIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.maleDaniaIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.maleDaniaTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.maleDaniaTERM.name}</span>
          </Link>
        </li>
        <li>
          <Link to={data.chlebyTERM.uri}>
            {data?.chlebyIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.chlebyIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.chlebyTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.chlebyTERM.name}</span>
          </Link>
        </li>
        <li>
          <Link to={data.zupyTERM.uri}>
            {data?.zupyIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.zupyIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.zupyTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.zupyTERM.name}</span>
          </Link>
        </li>
        <li>
          <Link to={data.salatkiTERM.uri}>
            {data?.salatkiIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.salatkiIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.salatkiTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.salatkiTERM.name}</span>
          </Link>
        </li>
        <li>
          <Link to={data.napojeTERM.uri}>
            {data?.napojeIMG?.featuredImage?.node?.localFile?.childImageSharp?.fluid && (
              <Image
                fluid={data.napojeIMG.featuredImage.node.localFile.childImageSharp.fluid}
                alt={data.napojeTERM.name}
                style={{ opacity: 0.9, position: 'inherit' }}
              />
            )}
            <span className="category-name">{data.napojeTERM.name}</span>
          </Link>
        </li>
    </ul>
    </section>
  )
}

export default HomeCategories