import React from "react"
import { useEffect } from 'react';
import { getCookieConsentValue } from "react-cookie-consent";
// import { useLocation } from 'react-router-dom';
// import { location } from "@reach/router"

const Adsense = ({ currentPath, isPage }) => {
  // const { location } = props
  // const { currentPath } = props

  // console.log(currentPath)

  // const location = useLocation();
  // const currentPath = location.pathname;

  const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled');

  useEffect(() => {
    
    // const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled');

    (window.adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=1;

    if([3622,39814,61077,65497].includes(isPage)) {}
    else {
      // window.adsbygoogle = window.adsbygoogle || []
      // window.adsbygoogle.push({})

      // console.log('adsense load')

      if (getGPDRCookie !== 'true') {
        (window.adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
        // console.log('cookie false')
      };

      (window.adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;
      // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

  }, [currentPath, isPage, getGPDRCookie])
  
  return (
    <></>
  )
}

export default Adsense