import React from "react"
import CookieConsent from "react-cookie-consent";
import { useEffect, useRef, useState} from 'react';

const Cookies = () => {

  const check = useRef(true)
  const [count, setCount] = useState(0)

  useEffect(() => {

    if (check.current) {
      check.current = false;
    
    } else {
      const adrino = document.createElement('script');
      adrino.src = '//mob.vendimob.pl/rp/98caa9d58f8f9347/1';
      adrino.async = true;

      // const gcse = document.createElement('script');
      // gcse.src = 'https://cse.google.com/cse.js?cx=009597372997567409640:dq1l164n8sk';
      // gcse.async = true;

      (typeof window !== 'undefined' && 
        (window.innerWidth <= 991 && (
          document.head.appendChild(adrino)
        ))
      )

      // document.head.appendChild(gcse)
  
      return () => {
        (typeof window !== 'undefined' && 
          (window.innerWidth <= 991 && (
            document.head.removeChild(adrino)
          ))
        )
        // document.head.removeChild(gcse)     
      }     
    }
  }, [count]);
  
  return (
    <CookieConsent
      location="bottom"
      buttonText="Akceptuję i przechodzę do strony"
      declineButtonText="Zmień ustawienia: tylko niezbędne pliki cookies"
      cookieName="gdpr_non-essential-enabled"
      buttonWrapperClasses="cookieConsent-buttons"
      contentClasses="cookieConsent-content"
      disableButtonStyles={true}
      // style={{ background: "#2B373B" }}
      contentStyle={{}}
      expires={365}
      onAccept={() => {
        window.trackGoogleAnalytics();
        (window.adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
        setCount(count+1);
      }}
      enableDeclineButton
      onDecline={() => {
        (window.adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
        (window.adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
      }}
      overlay
      // debug={true}
    >
      <p>Strona jest prowadzona przez PACI Paulina Stępień i wykorzystuje pliki cookies niezbędne jej do prawidłowego działania pod względem technicznym (tzw. niezbędne pliki cookies) oraz pliki cookies używane do celów marketingowych: korzystania z narzędzi analitycznych (Google Analitycs) i wyświetlania spersonalizowanych reklam (dopasowanych do twoich zainteresowań) przez sieci reklamowe współpracujące ze stroną kotlet.tv.</p><p>Jeżeli wyrażasz zgodę na wykorzystywanie wszystkich kategorii plików cookies, kliknij w przycisk <strong>„Akceptuję i przechodzę do strony”</strong>.</p><p>Jeżeli nie wyrażasz na to zgody, możesz ograniczyć zakres używanych plików cookies wyłącznie do niezbędnych plików cookies (na stronie będą wyświetlane reklamy niespersonalizowane). W tym celu kliknij opcję <strong>„Zmień ustawienia: tylko niezbędne pliki cookies”</strong>.</p><p>Informacje: <a href="https://kotlet.tv/polityka-prywatnosci">Polityka prywatności</a>, <a href="https://kotlet.tv/polityka-cookies">Polityka cookies</a>. Ustawienia plików cookies możesz w każdej chwili zmienić w ustawieniach swojej przeglądarki internetowej.</p>
    </CookieConsent>
  )
}

export default Cookies