import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import Cookies from "./cookies"
// import Image from "gatsby-image"
// import { getCookieConsentValue } from "react-cookie-consent";
import Search from "./search"
import Adsense from "../components/adsense"
// import AdsensePush from "../components/adsense-push"
import HomeCategories from "../components/home-categories"

const Layout = ({ currentPath, isHomePage, isPage, homePageNumber, totalPageNumber, children, archiveTitle, archiveDesc }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
      menuHeader: wpMenu(id: {}, locations: {eq: GATSBY_HEADER_MENU}) {
        menuItems {
          nodes {
            label
            path
            id
          }
        }
      }
      menuFooter: wpMenu(id: {}, locations: {eq: GATSBY_FOOTER_MENU}) {
        menuItems {
          nodes {
            label
            path
            id
          }
        }
      }
    }
  `)

  const title = data.wp.generalSettings.title
  const description = data.wp.generalSettings?.description
  const menuHeader = data.menuHeader.menuItems
  const menuFooter = data.menuFooter.menuItems

  // const categories = data.allWpCategory.nodes

  // const getGPDRCookie = getCookieConsentValue('gdpr_non-essential-enabled')

  return (

    <div className="global-wrapper" data-is-root-path={isHomePage}>
      
      <Adsense currentPath={currentPath} isPage={isPage} />

      <header className="global-header">

        <div className="logo">
          <h1 className="heading heading-home">
            <Link to="/">{parse(title)}</Link>
          </h1>
          <p>{parse(description)}</p>
        </div>

        <section className="search">
          <h1>Szukaj:</h1>
          {/* <div className="gcse-searchbox-only"></div> */}
          <Search currentPath={currentPath}/>
        </section>

        {!!menuHeader && (
          <nav className="menu">
            <ul className="menu-1">
            {menuHeader.nodes.map((menuEntry) => (
              <li className="item" key={menuEntry.id}>
                <Link to={menuEntry.path}>
                    {menuEntry.label}
                </Link>
              </li>
            ))}
          </ul>
          </nav>
         )}

         <aside className="sklep">
           <p><a href="https://domowa.tv/sklep">Zobacz <strong>moje ebooki</strong> z nowymi przepisami → <span className="kliknij">kliknij tutaj</span></a></p>
         </aside>

      </header>

      {(!!isHomePage && homePageNumber === 1) && (
        <HomeCategories/>
      )}

      <main className="global-main">

        {(!!archiveTitle && !isHomePage) && (
            <h1 className="term-name">{archiveTitle}</h1>
        )}

        {(!!archiveTitle && !isHomePage && homePageNumber > 1) && (
          <small className="term-page"><strong>Strona {homePageNumber}</strong> <span className="small">z</span> {totalPageNumber}</small> 
        )}

        {!!archiveDesc && (
            <p className="term-description">{archiveDesc}</p>
        )}

        {(!!isHomePage && homePageNumber === 1) && (
          <h1 className="home-main">Przepisy:</h1> 
        )}
        
        {children}
      </main>

      <footer className="global-footer">

        {!!menuFooter && (
          <nav>
            <ul className="menu-2">
            {menuFooter.nodes.map((menuEntry) => (
              <li key={menuEntry.id}>
                <Link to={menuEntry.path}>
                    {menuEntry.label}
                </Link>
              </li>
            ))}
          </ul>
          </nav>
         )}

        <p><small>© 2009 - {new Date().getFullYear()} PACI Paulina Stępień. Zobacz moje <a href="https://domowa.tv/sklep/">ebooki dietetyczne</a>.</small></p>
      </footer>

      <Cookies />

    </div>
  )
}

export default Layout
